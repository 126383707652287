import { getLocalStorage, setLocalStorage } from "../LocalStorage/localStorageProvider";

export const setCart = (product) => {
  console.log("🚀 ~ file: quoterProvider.js ~ line 4 ~ setCart ~ product", product);
  const cart = getLocalStorage('cart') || [];
  const exists = cart.find(productCart => productCart.id === product.id);
  if(!exists) {
    cart.push(product);
    setLocalStorage('cart', cart);
    return cart;
  }
}
export const removeCart = (product) => {
  let cart = getLocalStorage('cart') || [];
  cart = cart.filter(productCart=> productCart.id !== product.id);
  setLocalStorage('cart', cart);
  return cart;
}

export const getCart = () => {
  return getLocalStorage('cart') || [];
}

export const existsInCart = (mesureId) => {
  const cart = getCart();
  return !!cart.find(mesure => mesure.id.toString() === mesureId.toString());
}