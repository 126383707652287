import model from '../core/model';
const getDatabooks = async (params = {type: 'PLT', includeAll: false}) => {
  
  const databooks = await model.get('/databooks', {params});
  return databooks.data;
};
const getDatabooksPdf = async (databookIds, productName) => {
  
  const databookPdf = await model.get('/databooks/pdf', {params: {databookIds, type: 'PLT'}, responseType: 'blob'});
  const blob = new Blob([databookPdf.data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${productName}-${+new Date()}.pdf`;
  link.click();
  return databookPdf;
};

export {getDatabooks, getDatabooksPdf}