import React, { useEffect, useRef } from 'react'
import Bar from 'react-top-loading-bar'
import emitter from '../core/emitter';

const LoadingBar = () => {
  const ref = useRef(null);
  useEffect(() => {
    emitter.on('loadingBar', (value) => value ? ref.current.continuousStart() : ref.current.complete());
  })
  return (
    <Bar shadow height={3} color='#FFA301' ref={ref} />
  )
}

export default LoadingBar