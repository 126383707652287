import React, {useEffect, useState} from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import {getVideos} from "./apiVideos";
const Videos = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [videos, setVideos] = useState([]);
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];
  const fetchVideos = async () => {
    const fetchedVideos = await getVideos();
    setVideos(fetchedVideos)
  }

  useEffect(() => {
    fetchVideos();
  }, []);
  return(
<div className="container-page">
        <div className="header-form">
            <div className="title-icon">
                <img src="./img/new/icon_videos.svg" className="img-responsive" alt="Cart"/>
                <span>Videos</span>
            </div>
            <div className="header-callaction">
                <button onClick={ () => handleClick()}className="close-header close-al" id="button-close">
                    <img src="./img/icons/icon_close.svg" alt="close" className="img-responsive" />
                </button>
            </div>
        </div>
        <div className="container-fluid container-information scroll-object">
            <div className="row no-gutters">
                <div className="col-12">
                    <div className="container-tires row no-gutters">
                      { videos.map((video) => {
                        return(
                        <div key={video.id} className="container-videos">
                        <iframe title={video.title} src={video.description} frameBorder="0" allowFullScreen className="iframe-video" />
                            <div className="content-information-video">
                                <h4>{video.title}</h4>
                                <div className="video-description">
                                    {video.brand_id === 1 && <img src="/img/new/iso_continental.svg" alt=""/>}
                                    {video.brand_id === 2 && <img src="/img/new/iso_general.svg" alt=""/>}
                                    {video.brand.description} | {video.type_vide === 'ad' ? 'Anuncio' : 'Tecnología'}
                                </div>
                            </div>
                        </div>)
                      })}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export {Videos};