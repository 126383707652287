import React from "react";

const SegmentSelector =  ({onChange}) => {
 return (<>
  <select onChange={onChange} className="input-class text-center">
  <option value="">Segmento</option>
  <option value="automovil">Automovil</option>
  <option value="pick_up">Pick Up</option>
  <option value="suv">SUV</option>
  <option value="van">VAN</option>
  </select>
  <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
</>)
}
export {SegmentSelector};