import React, {useEffect, useState} from "react";
import {
  useNavigate
} from "react-router-dom";

const Toolbox = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const percents = [
    {
      typeId: 1,
      breakId: 1,
      percent1: 3,
      percent2: 2,

    },
    {
      typeId: 1,
      breakId: 2,
      percent1: 3,
      percent2: 3,

    },
    {
      typeId: 2,
      breakId: 1,
      percent1: 3.5,
      percent2: 3,

    },
    {
      typeId: 2,
      breakId: 2,
      percent1: 5,
      percent2: 4,

    },
    {
      typeId: 3,
      breakId: 1,
      percent1: 4,
      percent2: 3,

    },
    {
      typeId: 3,
      breakId: 2,
      percent1: 5.5,
      percent2: 4,

    },
    {
      typeId: 4,
      breakId: 1,
      percent1: 3,
      percent2: 2,

    },
    {
      typeId: 4,
      breakId: 2,
      percent1: 3,
      percent2: 3,

    },
    {
      typeId: 4,
      breakId: 1,
      percent1: 3,
      percent2: 2,

    },
  ]
  const [diameterO, setDiameterO] = useState(0);
  const [diameterR, setDiameterR] = useState(0);
  const [totalDiameterO, setTotalDiameterO] = useState(0);
  const [totalDiameterR, setTotalDiameterR] = useState(0);
  const [tolerance, setTolerance] = useState({total: 0, positive: 0, negative: 0});
  const [compatible, setCompatible] = useState(false);
  const [type, setType] = useState(0);
  const [breaks, setBreaks] = useState(0);
  const [widthO, setWithO] = useState(0);
  const [widthR, setWithR] = useState(0);
  const [ringO, setRingO] = useState(0);
  const [ringR, setRingR] = useState(0);
  const calculate = () => {
    if(!type || !breaks) {
      return;
    }
    let resultDiameterR = 0;
    let resultDiameterO = 0;
    let widthOriginal = 0;
    let widthReplacement = 0;
    let positive=0;
    let negative=0;
    let percent = percents.find((percent) => percent.typeId.toString() === type.toString() && percent.breakId.toString() === breaks.toString());
    let percentPositive = percent.percent1;
    let percentNegative = percent.percent2;

    if(diameterR < 100) {
      resultDiameterR=diameterR*25.4;
    }else{
      resultDiameterR=(((widthR/100)*diameterR)*2)+(ringR*25.4);
    }

    if(diameterO < 100) {
      resultDiameterO = diameterO*25.4;
    }else{
      resultDiameterO=(((widthO/100)*diameterO)*2)+(ringO*25.4);
    }
    resultDiameterR = resultDiameterR.toFixed(1);

    setTotalDiameterR(resultDiameterR);
    resultDiameterO = resultDiameterO.toFixed(1);
    setTotalDiameterO(resultDiameterO);
    let resultTolerance=((resultDiameterR/resultDiameterO)-1)*100;
    resultTolerance=resultTolerance.toFixed(1)
    positive=Math.abs(resultDiameterO*(1+(percentPositive/100))).toFixed(2);
    negative=Math.abs(resultDiameterO*(1-(percentNegative/100))).toFixed(2);
    setTolerance({total: resultTolerance, positive, negative});
    var canvas = document.getElementById("original_side");
    var canvas2 = document.getElementById("replace_side");
    var canvas3 = document.getElementById("side_over");
    var canvas4 = document.getElementById("front_over");
    var height = canvas.height;
    var width = canvas.width;
    var center = {x: width/2 -5, y: height/2};
    var ctx = canvas.getContext("2d");
    var ctx2= canvas2.getContext("2d");
    var ctx3 = canvas3.getContext("2d");
    var ctx4 = canvas4.getContext("2d");
    var proportion = 6;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
    ctx3.clearRect(0, 0, canvas3.width, canvas3.height);
    ctx4.clearRect(0, 0, canvas4.width, canvas3.height);
    if(diameterO < 100) {
      widthOriginal = widthO*25.4;
    }else{
      widthOriginal = widthO*4;
    }

    if(diameterR < 100) {
        widthReplacement = widthR*25.4;
    }else{
      widthReplacement = widthR * 4;
    }

    const isOk = Boolean(resultDiameterR <= positive && resultDiameterR >= negative);
    setCompatible(isOk);
    const color = isOk ? '#0EBC04' : '#BF0202';
    ctx.beginPath();
    ctx.arc(center.x,center.y,resultDiameterO/(2* proportion),0,2*Math.PI);
    ctx.fillStyle = "black";
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.arc(center.x,center.y,(ringO * 25.4)/(2* proportion),0,2*Math.PI);
    ctx.fillStyle = "grey";
    ctx.closePath();
    ctx.fill();

    
    

    ctx2.beginPath();
    ctx2.arc(center.x,center.y,resultDiameterR/(2* proportion),0,2*Math.PI);
    ctx2.fillStyle = "black";
    ctx2.closePath();
    ctx2.fill();
    ctx2.beginPath();
    ctx2.arc(center.x,center.y,(ringR * 25.4)/(2* proportion),0,2*Math.PI);
    ctx2.fillStyle = "grey";
    ctx2.closePath();
    ctx2.fill();

    
    

    ctx3.beginPath();
    ctx3.arc(center.x,center.y,resultDiameterO/(2* proportion),0,2*Math.PI);
    ctx3.fillStyle = "black";
    ctx3.closePath();
    ctx3.fill();

    ctx3.beginPath();
    ctx3.arc(center.x,center.y,(ringO * 25.4)/(2* proportion),0,2*Math.PI);
    ctx3.fillStyle = "grey";
    ctx3.closePath();
    ctx3.fill();

    ctx3.beginPath();
    ctx3.arc(center.x,center.y,resultDiameterR/(2* proportion),0,2*Math.PI);
    ctx3.strokeStyle = color;
    ctx3.stroke();

    ctx3.beginPath();
    ctx3.arc(center.x,center.y,(ringR * 25.4)/(2* proportion),0,2*Math.PI);

    ctx3.strokeStyle = color;
    ctx3.stroke();

    var start_point_x = center.x - diameterO/(2*proportion);
    var start_point_y = center.y - resultDiameterO/(2* proportion);

    var start_point_x_2 = center.x - diameterR/(2*proportion);
    var start_point_y_2 = center.y - resultDiameterR/(2* proportion);



    ctx4.beginPath();
    ctx4.rect(start_point_x, start_point_y, diameterO/proportion , resultDiameterO/proportion);
    ctx4.fillStyle = "black";
    ctx4.closePath();
    ctx4.fill();

    ctx4.beginPath();
    ctx4.rect(start_point_x_2, start_point_y_2, diameterR/proportion , resultDiameterR/proportion);
    console.log("🚀 ~ file: Toolbox.js ~ line 222 ~ calculate ~ resultDiameterR", resultDiameterR);
    console.log("🚀 ~ file: Toolbox.js ~ line 222 ~ calculate ~ widthReplacement", widthReplacement);
    console.log("🚀 ~ file: Toolbox.js ~ line 222 ~ calculate ~ start_point_y_2", start_point_y_2);
    console.log("🚀 ~ file: Toolbox.js ~ line 222 ~ calculate ~ start_point_x_2", start_point_x_2);
    ctx4.lineWidth = 1;
    ctx4.strokeStyle = color;
    ctx4.stroke();
    ctx4.closePath();
  }
  useEffect(() => {
    
  });
  return (
    <div className="container-page">
        <div className="header-form">
            <div className="title-icon">
                <img src="/img/new/icon_toolbox_b.svg" className="img-responsive" alt="Cart"/>
                <span>ToolBox</span>
            </div>
            <div className="header-callaction">
                <button onClick={() => handleClick('/toolbox')} className="btn-action gray-darker btn-header active" id="butonFilter">
                    Calcular Di&aacute;metro
                </button>
                <button onClick={() => handleClick('/toolbox_useful')} className="btn-action gray-darker btn-header" id="butonFilter">
                    Vida Útil
                </button>
                <button onClick={() => handleClick()} className="close-header" id="button-close">
                    <img src="/img/icons/icon_close.svg" alt="close" className="img-responsive"/>
                </button>
            </div>
        </div>
        <div className="container-fluid container-information scroll-object">
            <div className="row no-gutters d-flex">
                <div className="container-filter container-left-filters">
                    <div className="filters">
                        <div className="information-filters">
                            <div className="field-modal">
                                <p>Ingresa los valores para el cálculo</p> 
                                <div className="container-input">
                                    <select required onChange={(event) => setType(event.target.value)} className="input-class text-center">
                                      <option value="">*Escoje el tipo de vehículo</option>
                                      <option value={1}>AUTOMOVIL</option>
                                      <option value={2}>SUV/TODOTERRENO</option>
                                      <option value={3}>PICK UP</option>
                                      <option value="4">VAN</option>
                                    </select>
                                    <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                                </div>
                                <div className="container-input">
                                    <select required onChange={(event) => setBreaks(event.target.value)} className="input-class text-center">
                                    <option value="">*Escoje una opción</option>
                                    <option value={1}>ABS</option>
                                    <option value={2}>SIN ABS</option>
                                    </select>
                                    <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                                </div>
                            </div>
                            <div className="field-modal">
                                <h3>Medida original</h3>
                                <div className="container-information-filters">
                                <label>{diameterO > 100 ? 'Ancho de sección (mm)' : 'Diametro Ext (pulg)'}:</label>
                                    <input required onChange={(event) => setDiameterO(event.target.value)} className="input-class" value={diameterO}/>
                                </div>
                                <div className="container-information-filters">
                                <label>{diameterO > 100 ? 'Serie / Aspecto (%)' : 'Ancho Sección (pulg)'}:</label>
                                    <input required onChange={(event) => setWithO(event.target.value)} className="input-class" value={widthO}/>
                                </div>
                                <div className="container-information-filters">
                                    <label>Rin:</label>
                                    <input required onChange={(event) => setRingO(event.target.value)} className="input-class" value={ringO}/>
                                </div>
                                <div className="container-information-filters">
                                    <label>Diametro Total:</label>
                                    <input readOnly className="input-class" value={totalDiameterO}/>
                                </div>
                            </div>
                            <div className="field-modal">
                                <h3>Medida de Reemplazo</h3>
                                <div className="container-information-filters">
                                    <label>{diameterR > 100 ? 'Ancho de sección (mm)' : 'Diametro Ext (pulg)'}:</label>
                                    <input required onChange={(event) => setDiameterR(event.target.value)} className="input-class" value={diameterR}/>
                                </div>
                                <div className="container-information-filters">
                                    <label>{diameterR > 100 ? 'Serie / Aspecto (%)' : 'Ancho Sección (pulg)'}:</label>
                                    <input required onChange={(event) => setWithR(event.target.value)} className="input-class" value={widthR}/>
                                </div>
                                <div className="container-information-filters">
                                    <label>Rin:</label>
                                    <input required onChange={(event) => setRingR(event.target.value)} className="input-class" value={ringR}/>
                                </div>
                                <div className="container-information-filters">
                                    <label>Diametro Total:</label>
                                    <input readOnly className="input-class" value={totalDiameterR}/>
                                </div>
                            </div>
                        </div>
                        <div className="footer-filters">
                            <button onClick={() => calculate()} className="btn-action button-init w-100">Calcular</button>
                        </div>
                    </div>
                </div>
                <div className={`container-right-filters ${tolerance.total ? '': 'hidden'}`}>
                    <div className="container-tires row no-gutters">
                        <div className="section-toolbox">
                            <div className="info-tool">
                                <canvas id="original_side" width="200" height="160"></canvas>
                                <canvas id="replace_side" width="200" height="160"></canvas> 
                                <div className="txt-information-tool">
                                    <p className="w-100">{diameterO}/{widthO}R{ringO} vs <span className={`color-${compatible ? 'green' : 'red'}`}>{diameterR}/{widthR}R{ringR}</span></p>
                                </div>
                            </div>
                            <div className="info-tool">
                                <canvas id="side_over" width="200" height="160"></canvas>
                                <canvas id="front_over" width="200" height="160"></canvas> 
                                <div className="txt-information-tool">
                                    <p className="w-100">{diameterO}/{widthO}R{ringO} vs <span className={`color-${compatible ? 'green' : 'red'}`}>{diameterR}/{widthR}R{ringR}</span></p>
                                </div>
                            </div>
                        </div>
                        <div className="section-toolbox">
                            <div className="decriptioninformation">
                                <label>Diferencia:</label>
                                <span>{tolerance.total}%</span>
                            </div>
                        </div>
                        <div className="section-toolbox center-content">
                            <div className={`measure-data ${compatible ? 'green' : 'red'}`}>{compatible ? 'Medida compatible' : 'Cálculo no compatible'}</div>
                        </div>
                        <div className="section-toolbox flex-column">
                            <h3>Tolerancia positiva</h3>
                            <div className="decriptioninformation">
                                <label>Diferencia:</label>
                                <span>{tolerance.positive}</span>
                            </div>
                        </div>
                        <div className="section-toolbox flex-column">
                            <h3>Tolerancia negativa</h3>
                            <div className="decriptioninformation">
                                <label>Diferencia:</label>
                                <span>{tolerance.negative}</span>
                            </div>
                        </div>
                        <div className="section-toolbox flex-column">
                            <h3>Tolerancias sugeridas</h3>
                            <img src="/img/new/tolerancias.jpg" className="img-responsive" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export {Toolbox};