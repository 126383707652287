import React from "react";

const RingSelector =  ({rings, onChange, ring}) => {
 return (<>
  <select onChange={onChange} className="input-class text-center" value={ring || ''}>
      <option value=''>Rin*</option>
      { rings.map((ring, k) => {
        return(<option key={k} value={ring}>{ring}</option>)
      })}
  </select>
  <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
</>)
}
export {RingSelector};