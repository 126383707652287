import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import { getCart, removeCart } from "./quoterProvider";
import { getParams } from "../Params/apiParams";
import { SegmentSelector } from "../TireSelector/SegmentSelector";
import { WidthSelector } from "../TireSelector/WidthSelector";
import { SerieSelector } from "../TireSelector/SerieSelector";
import { RingSelector } from "../TireSelector/RingSelector";
import {getDatabooks} from "../Databook/apiDatabooks";
import {pluck, groupBy, round} from '../helpers/helpers';
import { ProductContainer } from "../Product/ProductContainer";
import {baseUrlImages} from '../config';
import {user} from '../Auth/provideUser';
import { createQuotation } from "./apiQuoter";
import {useAuth} from '../Auth/authContext';

const Quoter = () => {
  const history = useNavigate();
  const auth = useAuth();
  if(auth.user.data.country_id !== 1 && auth.user.data.country_id !== 2) {
    history('/home');
  }
  const [cart, setCart] = useState(getCart());
  const [client, setClient] = useState({name: null, phone: null, address: null, identity: null, email: null, distributor: user?.data?.distributor?.description});
  const [params, setParams] = useState([]);
  const [products, setProducts] = useState({});
  const [segment, setSegment] = useState(null);
  const [widths, setWiths] = useState([]);
  const [series, setSeries] = useState([]);
  const [rings, setRings] = useState([]);
  const [width, setWith] = useState(null);
  const [serie, setSerie] = useState(null);
  const [ring, setRing] = useState(null);
  const [priceDatabooks, setPriceDatabooks] = useState([]);
  const [databooks, setDatabooks] = useState([]);
  const [totals, setTotals] = useState({tax: 0, subTotal: 0, total: 0});
  const selectWith = (value) => {
    setWith(value);
    const series = [...new Set(databooks?.filter((databook) => databook.width.toString() === value.toString()).map((databook) =>databook.series))].sort();
    setSerie(null);
    setRing(null);
    setSeries(series);
  }
  const setClientData = (field, value) => {
    const newClient = {...client};
    newClient[field] = value;
    setClient(newClient);
  }
  const selectSerie = (value) => {
    setSerie(value);
    const rings = [...new Set(databooks
      .filter((databook) => databook.width.toString() === width.toString() && databook.series.toString() === value.toString())
      .map((databook) =>databook.ring))]
      .sort()
    setRing(null);
    setRings(rings);
  }
  const selectRing = (value) => {
    setRing(value);
  }
  const getSubtotal = () => {
      let values = cart.map(mesure => mesure.subTotal);
      values = values.filter(value => value);
        let subTotalS = values.reduce((a,b) => {
        return a + b;
        }, 0);
    subTotalS = round(subTotalS, 2);
    const taxValue = params.find((param) => param.type === 'TAX').value / 100;
    const tax = round(subTotalS *  taxValue, 2);
    const total = round(subTotalS + tax, 2);

    setTotals({tax, subTotal: subTotalS, total});
  }
  const fetchParams = async () => {
      const params = await getParams();
      setParams(params);
  }
  const downloadPdf = async () => {
    await createQuotation(client, cart, totals)
  }
  const fetchDatabooks = async () => {
    const fetchedDatabooks = await getDatabooks({type: 'PLT', includeAll: true});
    setPriceDatabooks([...fetchedDatabooks]);
    const fetchedDatabooksPluck = pluck(fetchedDatabooks, 'plt_databook');
    setDatabooks(fetchedDatabooksPluck);
    setWiths([...new Set(fetchedDatabooksPluck?.map((databook) =>databook.width))].sort());
  }
  const calculateData = (mesureId, value, name) => {
    const newCart = [...cart];
    const mesure = newCart.find((mesureCart) => mesureCart.id === mesureId);
    mesure[name] = value;
    const sub = mesure.quantity * mesure.price;
    const discount = mesure.discount || 0;
    const subTotal = sub - (sub * (discount / 100));
    mesure.subTotal = round(subTotal, 2);

    setCart(newCart);
    getSubtotal();
  }

  const addRemoveService = (paramId) => {
    let newCart = [...cart];
    const param = params.find((param) => param.id.toString() === paramId.toString());
    param.price = param.value;
    param.productDescription = param.description;
    param.productPicture = param.picture;
    const exists = !! cart.find((cartItem) => cartItem.id.toString() === paramId.toString() && cartItem.type === 'SERVICE');
    if(exists) {
        newCart = cart.filter((cartItem) => cartItem.id.toString() !== paramId.toString() || !cartItem.type);
    }
    if(!exists) {
        newCart.push(param);
    }

    setCart(newCart);
    getSubtotal();
  }
  const filterProducts = () => {
    let databooksFiltered = priceDatabooks.filter((databook) => {
      let conditional = databook.plt_databook.width.toString() === width && databook.plt_databook.series.toString() === serie && databook.plt_databook.ring.toString() === ring;
      if(segment) {
        conditional = conditional && databook.plt_databook[segment] === 1;
      }
      return conditional;
    });

    const byProducts = groupBy(databooksFiltered, 'product_id');
    setProducts(byProducts);
  }
  useEffect(() => {
    fetchParams();
    fetchDatabooks();
  }, []);
  return (    
  <div className="container-page cart">
  <div className="container-fluid container-information quoter-height scroll-object">
      <div className="container-cart">
          <div className="row no-gutters">
              <div className="col-12">
                  <div className="field-modal container-input">
                          <input type="text" value={client.name || ''} onChange={(event) => setClientData('name', event.target.value)} className="input-class text-center w-100" placeholder="Nombre"/>
                  </div>
                  <div className="field-modal container-input">
                          <input type="text" value={client.phone || ''} onChange={(event) => setClientData('phone', event.target.value)} className="input-class text-center w-100" placeholder="Teléfono"/>
                  </div>
                  <div className="field-modal container-input">
                          <input type="text" value={client.address || ''} onChange={(event) => setClientData('address', event.target.value)} className="input-class text-center w-100" placeholder="Dirección"/>
                  </div>
                  <div className="field-modal container-input">
                          <input type="text" value={client.identity || ''} onChange={(event) => setClientData('identity', event.target.value)} className="input-class text-center w-100" placeholder="Cédula/Identidad/RUC"/>
                  </div>
                  <div className="field-modal container-input">
                      <input type="email" value={client.email || ''} onChange={(event) => setClientData('email', event.target.value)} className="input-class text-center w-100" placeholder="Email"/>
                  </div>
                  <div className="field-modal container-input">
                      <input type="text" value={client.distributor || ''} onChange={(event) => setClientData('distributor', event.target.value)} defaultValue={user?.data?.distributor?.description}className="input-class text-center w-100" placeholder="Distribuidor"/>
                  </div>
                  <div className="field-modal">
                      <span>Buscar productos (no obligatorio):</span>
                      <div className="container-input">
                        <SegmentSelector onChange={(event) => setSegment(event.target.value)} />
                      </div>
                  </div>
                  <div className="field-modal">
                      <div className="container-input">
                        <WidthSelector widths={widths} onChange={(event) => selectWith(event.target.value)} />
                      </div>
                  </div>
                  <div className="field-modal">
                      <div className="container-input">
                        <SerieSelector series={series} serie={serie} onChange={(event) => selectSerie(event.target.value)} />
                      </div>
                  </div>
                  <div className="field-modal">
                      <div className="container-input">
                        <RingSelector ring={ring} onChange={(event) => selectRing(event.target.value)} rings={rings} />
                      </div>
                  </div>
                  <div className="field-modal">
                      <button onClick={() => filterProducts()} className="btn-action button-init w-100">Buscar productos</button>
                  </div>
                  <div className="field-modal">
                <div className="container-tires row no-gutters on-quoter">
                    {Object.keys(products).map((productId) => {
                      const databookByProduct = products[productId];
                      const product = databookByProduct[0]?.product;
                      if(product?.brand_id === 1){
                    return(<ProductContainer key={product.id} product={product} databooks={databookByProduct} refreshCart={setCart} />)
                      }
                    })}
                    {Object.keys(products).map((productId) => {
                      const databookByProduct = products[productId];
                      const product = databookByProduct[0]?.product;
                      if(product?.brand_id === 2) {
                        return(<ProductContainer key={product.id} product={product} databooks={databookByProduct} refreshCart={setCart} />)
                          }
                    })}
                </div>
            </div>
                  <div className="field-modal">
                      <h3>Agregar servicios</h3>
                      <div className="container-services">
                          {params.filter((param) => param.type === 'SERVICE' && param.app === 'PLT').map((param, k) => {
                              return(
                          <div key={k} className="container-checkbox">
                              <span>{param.description}</span>
                              <label className="container-c">
                                  <input onClick={(event) => addRemoveService(event.target.value)} type="checkbox" value={param.id} className="input-checkbox" />
                                  <span className="checkmark"></span>
                              </label>
                          </div>)
                          })}
                      </div> 
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div className="container-information container-button-bottom">
      <div className="container-up scroll-object">
          <div className="container-cart">
              <div className="col-12 cart-description">
              {cart.map((mesure, k) => {
                  return(<div key={k} className="container-tire-cotizador">
                      <div className="img-tire img-tire-cotizador">
                          <img src={`${baseUrlImages}/${mesure.productPicture}`} alt="" className="img-responsive" />
                      </div>
                      <div className="content-tire-cotizador">
                          <div className='info-app'>
                              <div className="title-information padding-cotizador">
                                  <div className="circle-color circle-big yellow">&nbsp;</div>
                                  <span>{mesure.productDescription} {mesure.mesure} {mesure.ic_iv} {mesure.code ? `(${mesure.code})` : ''}</span>
                              </div>
                              {mesure.type === 'SERVICE' && (mesure.description.toLowerCase().includes('otros')) && <div className="text-cotizador">
                                  <span className="light">Descripción:</span>
                                  <input type="text" onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)} name="productDescription" className="line-cotizador input-class input-cotizador"  />
                              </div>}
                              <div className="text-cotizador">
                                  <span className="light">Valor unitario:</span>
                                  <input type="number" defaultValue={mesure.price || 0} onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)} name="price" className="line-cotizador input-class input-cotizador" value={mesure.price || 0} />
                              </div>
                              <div className="text-cotizador">
                                  <span className="light">Cantidad:</span>
                                  <input type="number" onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)} name="quantity" className="line-cotizador input-class input-cotizador" />
                              </div>
                              <div className="text-cotizador">
                                  <span className="light">Descuento%:</span>
                                  <input type="number" className="line-cotizador input-class input-cotizador" onChange={(event) => calculateData(mesure.id, event.target.value, event.target.name)} name="discount" />
                              </div>
                              <div className="text-cotizador back-yellow">
                                  <span>Valor subtotal:</span>
                                  <span>{mesure.subTotal}</span>
                              </div>
                          </div>   
                      </div>
                      { !mesure.type &&
                        <a href="#" onClick={() => {removeCart(mesure); setCart(getCart())}} className="cotizador-close"><img src="./img/icons/icon_close_red.svg" className="img-responsive" alt="Cart"/></a>
                      }
                  </div>)
              })}
                  <div className="content-tire-cotizador black">
                      <div className='info-app'>
                          <div className="text-cotizador">
                              <span className="light">Subtotal:</span>
                              <span>{totals.subTotal}</span>
                          </div>
                          <div className="text-cotizador">
                              <span className="light">{params.find((param) => param.type === 'TAX')?.description} {params.find((param) => param.type === 'TAX')?.value}%:</span>
                              <span>{totals.tax}</span>
                          </div>
                          <div className="text-cotizador">
                              <span>Total:</span>
                              <span>${totals.total}</span>
                          </div>
                      </div>   
                  </div>
              </div>
          </div>
      </div>
      <div className="container-download-buttons">
          <div className="button-filter">
              <button onClick={() => setClient({})} className="btn-optionfilter" data-type="measure">
                 Borrar cotización
              </button>
              <button onClick={() => downloadPdf()} className="btn-optionfilter active" data-type="design">
                  Descargar pdf
              </button>
          </div> 
      </div>
      
  </div>
</div>);
}
export {Quoter};