import React, {useEffect, useState} from "react";
import {
  Link, useNavigate
} from "react-router-dom";
import {getProducts} from "../Product/apiProducts";
import {baseUrlImages} from '../config';
import {SearchProducts} from '../Search/SearchProducts';
import {sortProduct} from '../helpers/helpers';
import {ProductContainer} from '../Product/ProductContainer';
const ProductFactBooks = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    const fetchedProducts = await getProducts({type: 'PLT', includeAll: false});
    setProducts(fetchedProducts)
  }
  useEffect(() => {
    fetchProducts();
  }, []);
  return(
<div className="container-page">
        <div className="header-form">
            <div className="title-icon">
                <img src="/img/new/icon_factbook.svg" className="img-responsive" alt="Cart"/>
                <span>ProductFact</span>
            </div>
            <div className="header-callaction search-products">
                <SearchProducts products={products} originalProducts={[...products]} filterProducts={setProducts} />
                <button onClick={() => handleClick()} className="close-header" id="button-close">
                    <img src="/img/icons/icon_close.svg" alt="close" />
                </button>
            </div>
        </div>
        <div className="container-fluid container-information scroll-object">
            <div className="row no-gutters">
                <div className="col-12">
                <div className="container-tires row no-gutters">
                    {products.sort(sortProduct).map((product) => {
                        return (<ProductContainer factbook key={product.id} product={product} />)
                    })}
                </div>
                </div>
            </div>
        </div>
    </div>
     )
    }
    
    export {ProductFactBooks};