import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import {PrivateRoute} from './Routes/privateRoute';
import {ProvideAuth} from './Auth/provideAuth';
import {Login} from './Auth/Login';
import {Header} from './Header/Header';
import {Home} from './Home/Home';
import {HomeContainer} from './Home/HomeContainer';
import {Toolbox} from './Toolbox/Toolbox';
import {UsefulLife} from './Toolbox/UsefulLife';
import {Products} from './Product/Products';
import {Product} from './Product/Product';
import {Videos} from './Videos/Videos';
import {ProductFactBook} from './ProductFactBook/ProductFactBook';
import {Guides} from './Guide/Guides';
import {Quoter} from './Quoter/Quoter';
import {TireSelector} from './TireSelector/TireSelector';
import {ProductFactBooks} from './ProductFactBook/ProductFactBooks';
import {getLocalStorage} from './LocalStorage/localStorageProvider';
import LoadingBar  from "./LoadingBar/LoadingBar";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
const App = () => {
  const user = getLocalStorage('user');

  if(user) {
      ReactGA.initialize('G-EB6SNLLLFQ', { gaOptions: {userId: user.data.id, email: user.data.email}});
      ReactGA.send('pageview');
  }else{
      ReactGA.initialize('G-EB6SNLLLFQ', {});
      ReactGA.send('pageview');
  }
  return (
    <ProvideAuth>
      <Router>
      <Header/>
      <ToastContainer position="top-center" />
        <LoadingBar />
        <Routes>
            <Route exact path="/">
            {user ? <Route path="/" element={<Navigate replace to="/home" />} /> :  <Route path="/" element={<Navigate replace to="/login" />} />}
            </Route>
            <Route path="/login" element={ <Login />} />
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/home' element={<Home/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/' element={<Home/>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/toolbox' element={<HomeContainer title="ToolBox"><Toolbox/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/toolbox_useful' element={<HomeContainer title="ToolBox Vida Útil"><UsefulLife/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/products' element={<HomeContainer title="Productos"><Products/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/product/:id' element={<HomeContainer><Product/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/videos' element={<HomeContainer title="Videos"><Videos/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/product_guides' element={<HomeContainer title="Guías de producto"><Guides/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/product_factbooks' element={<HomeContainer title="ProductFactbooks"><ProductFactBooks/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/product_factbook/:id' element={<HomeContainer><ProductFactBook/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/quoter' element={<HomeContainer title="Cotizador"><Quoter/></HomeContainer>}/>
            </Route>
            <Route exact path='/' element={<PrivateRoute/>}>
                  <Route exact path='/tireselector' element={<HomeContainer title="TireSelector"><TireSelector/></HomeContainer>}/>
            </Route>
            </Routes>
      </Router>
    </ProvideAuth>
  );
}

export default App;