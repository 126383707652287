import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {getProducts} from "./apiProducts";
import {baseUrlImages} from '../config';
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  DirectionalLight,
  HemisphereLight,
  Vector3,
  Clock,
} from "three";
import OrbitControls from "three-orbitcontrols";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { setCart, existsInCart } from "../Quoter/quoterProvider";
import { getDatabooksPdf } from "../Databook/apiDatabooks";
import {Helmet} from "react-helmet";

const Product = () => {
  let container;
  let camera;
  let renderer;
  let scene;
  let controls;

  const mixers = [];
  const clock = new Clock();
  const init = (product) => {
    container = document.querySelector("#scene-container");
  
    scene = new Scene();
    createCamera();
    createLights();
    loadModels(product);
    createControls();
    createRenderer();
  
    renderer.setAnimationLoop(() => {
      update();
      render();
    });
  }

  const createCamera = () => {
    const fov = 20;
    const aspect = container.clientWidth / container.clientHeight;
    const near = 0.5;
    const far = 1000;
    camera = new PerspectiveCamera(fov, aspect, near, far);
    camera.position.set(0, 0.5, 2.5);
  }
  
  const createLights = () => {
    const mainLight = new DirectionalLight(0xffffff, 10);
    mainLight.position.set(10, 20, 10);
    const main2 = new DirectionalLight(0xffffff, 10);
    main2.position.set(-45, 45, 10);
  
    const hemisphereLight = new HemisphereLight(0xddeeff, 0x202020, 10);
    scene.add(mainLight, hemisphereLight, main2);
  }
  
  const loadModels = (product) => {
    const loader = new GLTFLoader();
    const onLoad = (result, position) => {
      const model = result.scene.children[0];
      model.position.copy(position);
      // model.scale.set(1, 1, 1);
      model.rotation.x = 0;
      model.rotation.y = 49.5;
      model.rotation.z = 0;
  
      scene.add(model);
    };
  
    const onProgress = progress => {console.log(progress)};
    const tire = new Vector3(0, 0, 0);
    loader.load(
      `/renders/${product.render_web}.glb`,
      gltf => onLoad(gltf, tire),
      onProgress
    );
  }
  
  const createRenderer = () => {
    renderer = new WebGLRenderer({ antialias: true, alpha: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.gammaFactor = 2.2;
    renderer.gammaOutput = true;
    renderer.shadowMap.enabled = true;
    renderer.physicallyCorrectLights = true;
  
    container.appendChild(renderer.domElement);
  }
  
  const createControls = () => {
    controls = new OrbitControls(camera, container);
    controls.target.set( 0, 0, 0 );
    controls.update();
    controls.enablePan = true;
    controls.enableDamping = true;
  }
  const update = () => {
    const delta = clock.getDelta();
    mixers.forEach(mixer => mixer.update(delta));
  }
  
  const render = () => {
    renderer.render(scene, camera);
  }
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];
  const history = useNavigate();
  const handleClick = (route = '/products') => {
    history(route);
  }
  const [products, setProducts] = useState([]);
  const [utqgs, setUtqgs] = useState([]);
  const [mesures, setMesures] = useState([]);
  const [width, setWith] = useState(null);
  const [series, setSeries] = useState(null);
  const [ring, setRing] = useState(null);
  let product = {characteristics: [], plt_databooks: []};
  if(products.length) {
    product = products[0];
  }
  const fetchProducts = async () => {
    const fetchedProducts = await getProducts({id, includeAll: true});
    if(fetchedProducts[0].render) {
      init(fetchedProducts[0]);
    }
    const utqgs = fetchedProducts[0]?.utqgs?.sort((a, b) => {
      if (a.sort > b.sort) {
        return 1;
      }
      if (a.sort < b.sort) {
        return -1;
      }
      return 0;
    })
    setUtqgs(utqgs);
    setProducts(fetchedProducts)
  }
  const downloadPdf = async () => {
    const ids = mesures.map((mesure) => mesure.id)
    await getDatabooksPdf(ids , product.description);
  }
  const pushMesure = () => {
    const mesurePicked = product.plt_databooks
    .find((databook) => databook.width.toString() === width.toString() && databook.series.toString() === series.toString() && databook.ring.toString() === ring.toString())
    const exists = mesures.find(mesure => mesure.id === mesurePicked.id);
    const newMesures = [...mesures];
    if(!exists) {
      newMesures.push(mesurePicked);
      setMesures(newMesures);
    }
  }
  const removeMesure = (id) => {
    const newMesures = mesures.filter(mesure => mesure.id !== id);
      setMesures(newMesures);
  }

  const brands =  {
    1: 'continental',
    2: 'general',
    3: 'barum',
    4: 'sportiva',
  }
  const path = brands[product?.brand_id];

  useEffect(() => {
    fetchProducts();
}, []);
  return (
    <div className="container-page">
        <Helmet>
            <title>{ `Contigo - ${ product.description }` }</title>
            <meta name="description" content="Nested component" />
        </Helmet>
        <div className="header-form">
            <div className="title-icon">
                <img src="/img/new/icon_fichatecnica_b.svg" className="img-responsive" alt="Cart"/>
                <span>Ficha t&eacute;cnica</span>
            </div>
            <div className="header-callaction">
            <button onClick={() => handleClick()} className="close-header close-al" id="button-close">
                <img src="/img/icons/icon_close.svg" alt="close" className="img-responsive"/>
            </button>
            </div>
        </div>
        <div className="container-fluid container-information scroll-object">
        <div>
                <div className="tire-detail">
                    <div className="header-modal tire-detail">
                        <div className="title-icon-continental">
                            <img src={`/img/icon_${path}.png`} className="img-responsive" alt="Cart"/>
                            <span>{product.description}</span>
                        </div>
                    </div>
                    <div className="body-modal scroll-body scroll-object">
                        <div className="col-12 col-sm-7">
                            <div className="content-information flex-column">
                                <p>{product.phrase}</p>
                            </div>
                            <div className="content-information flex-column">
                                <div className="title-h2">
                                    <h2>UTQG</h2>
                                </div>

                                <div className="changes-c">
                                  { utqgs
                                      .map((utqg, k) => {
                                        if(k >= 3) return null;
                                        return (<div key={k} className="content-c">
                                        <div className="title-modal-c">{utqg.type}</div>
                                        <p>{utqg.description}</p>
                                    </div>)
                                      })
                                }
                                </div>
                                { utqgs.length > 3 &&
                                <div className="changes-c">
                                  { utqgs
                                      .map((utqg, k) => {
                                        if(k > 2) {
                                        return (<div key={k} className="content-c">
                                        <div className="title-modal-c">{utqg.type}</div>
                                        <p>{utqg.description}</p>
                                    </div>)
                                        }
                                        return null;
                                      })
                                }
                                </div>
                                }
                                <div className="img-icons-modal">
                                  { product?.icons?.map((icon, key) => {
                                    return (<img key={key} className="icon-modal" src={`${baseUrlImages}/icons/${path}/${icon.picture?.replace('png', 'svg')}`} alt=""/>)
                                    })
                                  }
                                </div>
                            </div>
                            <div className="content-information flex-column">
                                <div className="title-h2">
                                    <h2>Tecnolog&iacute;a</h2>
                                </div>
                                <div className="img-icons-modal">
                                { product?.technologies?.map((technology, k) => {
                                    return( <img key={k} className="icon-modal" src={`${baseUrlImages}/technologies/${technology.icon}`} alt=""/>)
                                  })
                                }
                                </div>
                            </div>
                            <div className="content-information flex-column">
                                <div className="title-h2">
                                    <h2>Características</h2>
                                </div>
                                <p>
                                { product?.characteristics?.filter((characteristic) => characteristic.type === 'CHARACTERISTIC').map((characteristic, k) => {
                                    return(
                                      <span key={k}>
                                        {characteristic.description}
                                        <br/> <br/>
                                    </span>
                                    )
                                  })
                                }
                                </p>
                            </div>
                            <div className="content-information flex-column">
                                <div className="title-h2">
                                    <h2>Beneficios</h2>
                                </div>
                                <p>
                                { product?.characteristics?.filter((characteristic) => characteristic.type === 'BENEFIT').map((characteristic, k) => {
                                    return(
                                      <span key={k}>
                                        {characteristic.description}
                                        <br/> <br/>
                                    </span>
                                    )
                                  })
                                }
                                </p>
                            </div>
                            <div className="content-information flex-column">
                                <div className="title-h2">
                                    <h2>Generar ficha técnica</h2>
                                </div>
                                <div className="container-input margin-top-input">
                                    <select onChange={(event) => setWith(event.target.value)} className="input-class text-center">
                                        <option>*Ancho</option>
                                        { [...new Set(product?.plt_databooks?.map((databook) =>databook.width))].sort().map((width, k) => {
                                    return(<option key={k} value={width}>{width}</option>)
                                  })
                                }
                                    </select>
                                    <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                                </div>
                                <div className="container-input margin-top-input">
                                    <select onChange={(event) => setSeries(event.target.value)} className="input-class text-center">
                                        <option>*Serie</option>
                                        { [...new Set(product?.plt_databooks?.filter((databook) => databook.width?.toString() === width?.toString()).map((databook) =>databook.series))].sort().map((series, k) => {
                                    return(<option key={k} value={series}>{series}</option>)
                                  })
                                }
                                    </select>
                                    <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                                </div>
                                <div className="container-input margin-top-input">
                                    <select onChange={(event) => setRing(event.target.value)} className="input-class text-center">
                                        <option>*Rin</option>
                                        { [...new Set(product?.plt_databooks?.filter((databook) =>databook.width?.toString() === width?.toString() && databook.series?.toString() === series?.toString()).map((databook) =>databook.ring))].sort().map((ring, k) => {
                                    return(<option key={k} value={ring}>{ring}</option>)
                                  })
                                }
                                    </select>
                                    <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                                </div>
                                <div className="content-modal-button">
                                    <button onClick={() => pushMesure()} className="btn-action border-100 button-init">
                                        <span>Seleccionar</span>
                                        
                                    </button>
                                </div>
                            </div>
                            <div className="content-information flex-column">
                                <div className="title-h2">
                                    <h2>Medidas seleccionadas</h2>
                                </div>
                                {mesures.map((mesure, k) => {
                                  mesure.productDescription = product.description;
                                  mesure.productPicture = product.picture;
                                  mesure.product = {type: product.type};
                                  return(<div key={k} className="content-add-cart">
                                    <p>{mesure.mesure} - ({mesure.code})</p>
                                    <div className="icons-cart">
                                    {!existsInCart(mesure.id) && <a onClick={() => setCart(mesure)} href="#"><img src="/img/icons/icon_cart_plus_dark.svg" className="img-responsive" alt="Cart"/></a>}
                                      <a href="#" onClick={() => removeMesure(mesure.id)}><img src="/img/icons/icon_close_red.svg" className="img-responsive" alt="remove"/></a>
                                    </div>
                                </div>)})}
                                <div className="content-modal-button">
                                    <button onClick={() => downloadPdf()} className="btn-action border-100 button-init">
                                        <span>Generar ficha técnica</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-5 tire-side-modal">
                          {!product.render_web &&
                              <div className="img-tire img-tire-modal">
                                  <img src={`${baseUrlImages}/${product.picture}`} alt="" className="img-responsive" />
                              </div>
                          }
                           <div id="scene-container" style={{marginTop: '50px', width: '100%', height: '500px', cursor: 'grab'}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export {Product};