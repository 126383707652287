import React from "react";

const SerieSelector =  ({series, onChange, serie}) => {
 return (<>
  <select onChange={onChange} className="input-class text-center" value={serie || ''}>
      <option value=''>Serie*</option>
      { series.map((serie, k) => {
        return(<option key={k} value={serie}>{serie}</option>)
      })}
  </select>
  <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
</>)
}
export {SerieSelector};