import React, {useEffect, useState} from "react";
import {
  useNavigate
} from "react-router-dom";
import {getDatabooks} from "../Databook/apiDatabooks";
import {getProducts} from "../Product/apiProducts";
const UsefulLife = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [databooks, setDatabooks] = useState([]);
  const [type, setType] = useState('code');
  const [products, setProducts] = useState([]);
  const [productsMesure, setProductsMesure] = useState([]);
  const [mesureMesure, setMesureMesure] = useState([]);
  const [product, setProduct] = useState({});
  const [productMesure, setProductMesure] = useState({});
  const [databook, setDatabook] = useState({});
  const [code, setCode] = useState('');
  const [actualDepth, setActualDepth] = useState(0);
  const [result, setResult] = useState({actualLife: 0, wear: 0});
  const fetchDatabooks = async () => {
    const fetchedDatabooks = await getDatabooks();
    setDatabooks(fetchedDatabooks)
  }
  const fetchProducts = async () => {
    const fetchedProducts = await getProducts();
    setProducts(fetchedProducts)
  }
  const findDatabook = ()=> {
    const newCode = code.charAt(0) === '0' ? code.substr(1) : code;
    const _databook_ = databooks.find((databook) => databook.plt_databook.code === newCode);
    const _product_ = products.find((product) => product.id === _databook_.plt_databook.product_id);
    setDatabook(_databook_);
    setProduct(_product_);
  }
  const filterBrand = (brandId) => {
    const newProducts = products.filter((product) => product.brand_id === brandId);
    setProductsMesure(newProducts);
  }
  const calculate = () => {
    const depthMm = databook.plt_databook.depth_mm;
    var actualLife = Math.floor((actualDepth - 1.6) / (depthMm - 1.6) * 100);
    var wear = 100 - actualLife;
    setResult({actualLife, wear});
  }
  useEffect(() => {
    fetchDatabooks();
    fetchProducts();
}, []);
  return (
    <div className="container-page options">
    <div className="header-form options">
        <div className="title-icon">
            <img src="/img/new/icon_toolbox_b.svg" className="img-responsive" alt="Cart"/>
            <span>ToolBox</span>
        </div>
        <div className="header-callaction options">
        <button onClick={() => handleClick('/toolbox')} className="btn-action gray-darker btn-header" id="butonFilter">
                    Calcular Di&aacute;metro
                </button>
                <button onClick={() => handleClick('/toolbox_useful')} className="btn-action gray-darker btn-header active" id="butonFilter">
                    Vida Útil
                </button>
                <button onClick={() => handleClick()} className="close-header" id="button-close">
                    <img src="/img/icons/icon_close.svg" alt="close" className="img-responsive"/>
                </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row no-gutters">
            <div className="header-form options">
            <div className="title-icon">
            </div>
              <div className="header-callaction options">
                  <button onClick={() => setType('code')} className={`btn-action gray-darker btn-header ${type === 'code' ? 'active': null}`} id="butonFilter">
                      Código
                  </button>
                  <button onClick={() => setType('mesure')} className={`btn-action gray-darker btn-header ${type === 'mesure' ? 'active': null}`} id="butonFilter">
                      Medida
                  </button>
              </div>
              <div className="header-callaction options">
              </div>
            </div>
            <div className="col-12 container-filter">
                <div className="filters">
                    <div className="information-filters">
                        {type === 'code' && <div>
                          <div className="field-modal">
                              <p>Define los parámetros de búsqueda por artículo o código:</p> 
                              <div className="content-input">
                                  <input  onChange = {(event) => setCode(event.target.value)}value={code} className="input-class text-center w-100" placeholder="Número o código"/>
                                  <button onClick={() => findDatabook()} className="bnt-buscar">Buscar</button>
                              </div>
                          </div>
                        </div>}
                        {type === 'mesure' && <div>
                          <div className="field-modal">
                              <p>Define los parametro de búsqueda por medida del neumático:</p>
                              <div className="button-filter">
                                  <button onClick={() => filterBrand(1)} className="btn-optionfilter continental" data-type="measure">
                                      <img src="/img/new/continental.svg" className="img-responsive" alt=""/>
                                  </button>
                                  <button onClick={() => filterBrand(2)} className="btn-optionfilter generaltire" data-type="design">
                                      <img src="/img/new/generaltire.svg" className="img-responsive" alt=""/>
                                  </button>
                              </div> 
                          </div>
                          <div className="field-modal">
                              <div className="container-input">
                                  <select onChange={(event) => setProductMesure(event.target.value)} className="input-class text-center">
                                      <option>Nombre del producto</option>
                                      {productsMesure.map((product) => {
                                        return(<option key={product.id} value={product.id}>{product.description}</option>)
                                      })}
                                  </select>
                                  <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                              </div>
                          </div>
                          <div className="field-modal">
                              <div className="container-input">
                                  <select onChange={(event) => {setMesureMesure(event.target.value)}} className="input-class text-center">
                                      <option>Medida</option>
                                      {databooks.filter((databook) => databook.plt_databook.product_id.toString() === productMesure)
                                      .map((databook) => databook?.plt_databook?.mesure)
                                      .filter((value, index, self) => self.indexOf(value) === index)
                                      .map((databook, i) => {
                                        return(<option key={i} value={databook}>{databook}</option>)
                                      })}
                                  </select>
                                  <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                              </div>
                          </div>
                          <div className="field-modal">
                              <div className="container-input">
                                  <select onChange={(event) => {setCode(event.target.value);}} className="input-class text-center">
                                      <option>C&oacute;digo</option>
                                      {databooks.filter((databook) => databook.plt_databook.product_id.toString() === productMesure && databook.plt_databook.mesure === mesureMesure)
                                      .map((databook, i) => {
                                        return(<option key={i} value={databook.plt_databook.code}>{databook.plt_databook.code}</option>)
                                      })}
                                  </select>
                                  <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                              </div>
                          </div>
                        </div>}
                        {databook.id && <div className="field-modal p-green">
                            <p className="color-green text-center">{databook.plt_databook.mesure} {databook.plt_databook.ic_iv} {databook.plt_databook.ring_protection} {databook.plt_databook.side} {product.description} </p>
                            <p className="color-green text-center">Prof. labrado original: {databook.plt_databook.depth_mm}mm</p> 
                        </div>}
                        <div className="field-modal">
                            <h3>Ingresa la profundidad actual del neumático:</h3>
                            <div className="container-information-filters margintop-10">
                                <label>*Profundidad actual (mm):</label>
                                <input onClick={() => findDatabook()} onChange = {(event) => setActualDepth(event.target.value)}value={actualDepth} className="input-class"/>
                            </div>
                        </div>
                        <div className="field-modal border-top">
                            <div className="section-toolbox flex-column">
                                <div className="decriptioninformation no-border">
                                    <label>Profundidad labrado original:</label>
                                    <span>{databook.plt_databook?.depth_mm} mm</span>
                                </div>
                            </div>
                            <div className="section-toolbox flex-column">
                                <div className="decriptioninformation no-border">
                                    <label>Profundidad labrado actual:</label>
                                    <span>{actualDepth} mm</span>
                                </div>
                            </div>
                            <div className="section-toolbox flex-column">
                                <div className="decriptioninformation no-border">
                                    <label>Nivel de desgaste:</label>
                                    <span>{result.wear}%</span>
                                </div>
                            </div>
                            <div className="section-toolbox flex-column">
                                <div className="decriptioninformation no-border">
                                    <label>Vida útil remanente:</label>
                                    <span>{result.actualLife}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-filters">
                        <button onClick = {() => calculate()}className="btn-action button-init w-100">Calcular</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}
export {UsefulLife};