import model from '../core/model';

export const createQuotation = async (client, cart, totals) => {
  
  const quoterPdf = await model.post('/quoter/pdf', {client, cart, totals}, {responseType: 'blob'});
  const blob = new Blob([quoterPdf.data], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${client.name}-${+new Date()}.pdf`;
  link.click();
  return quoterPdf;
};