import React, {useEffect, useState} from "react";
import {
  useNavigate,
} from "react-router-dom";

const Guides = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [guideSelected, setGuide] = useState({});
  const guides = [
    {
      type: 'AUTO',
      brandId: 1,
      description: 'https://online.fliphtml5.com/tytcs/rdir/',
      picture: '/img/foto_4.png'
    },
    {
      type: 'AUTO',
      brandId: 1,
      description: 'https://online.fliphtml5.com/tytcs/ppfd/',
      picture: '/img/auto_1.jpg'
    },
    {
      type: 'SUV',
      brandId: 1,
      description: 'https://online.fliphtml5.com/tytcs/jqfm/',
      picture: '/img/suv_1.jpg'
    },
    {
      type: 'AUTO',
      brandId: 1,
      description: 'https://online.fliphtml5.com/tytcs/hebu/',
      picture: '/img/auto_2.jpg'
    },
    {
      type: 'SUV',
      brandId: 1,
      description: 'https://online.fliphtml5.com/tytcs/nsts/',
      picture: '/img/suv_2.jpg'
    }
  ]
  useEffect(() => {

  }, []);
  return(
<div className="container-page">
<div className="header-form">
    <div className="title-icon">
        <img src="img/new/icon_guias_b.svg" className="img-responsive" alt="Cart"/>
        <span>GuíasProductos</span>
    </div>
    <div className="header-callaction">
        <button onClick={() => handleClick()} className="close-header close-al" id="button-close">
            <img src="img/icons/icon_close.svg" alt="close" className="img-responsive" />
        </button>
    </div>
</div>
<div className="container-information scroll-object container-guiasp">
    <div className="container-pdf">
      {guides.map((guide, i) => {
        return(<div key={i} className={`pdf-info ${guide.description === guideSelected.description ? 'active' : ''}`}>
            <button onClick={() => setGuide(guide)}>
            <img src={guide.picture} className="img-responsive" alt={guide.picture} />
            </button>
        </div>)
      })}   
    </div>
    {guideSelected && <div className="iflame-pdf">
        <iframe frameBorder='0'  width='100%' height='100%'  title={guideSelected.type} src={guideSelected.description} type='text/html' allowFullScreen scrolling='no' marginWidth='0' marginHeight='0'></iframe>
    </div>}
</div>
</div>
  )
}

export {Guides};