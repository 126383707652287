import React, {useState} from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import {useAuth} from './authContext';

const Login = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
  let history = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let { from } = location.state || { from: { pathname: "/home" } };
  let login = async (event) => {
    event.preventDefault();
    await auth.signin({email, password, rememberMe: true});
    history(from, {replace: true});
  };

  return (
    <div>
      <div className="login-continer">
        <div className="login-form" id='login-form'>
            <form onSubmit={login}>
            <div className="container-form">
                <div className="content-header">
                    <img src="./img/ico_contigo.png" className="img-responsive" alt="contigo"/>
                    <p>
                        Sé parte de <span className="medium"><span className="color-yellow">CONTI</span>GO</span><br/>
                        <span className="font-light">y sé un experto en llantas</span>
                    </p>
                </div>
                <div className="container-input">
                    <input type='email' autocomplete='off' className="input-class white login text-center" onChange={e => setEmail(e.target.value)} name="email" required placeholder="Correo electrónico" />
                </div>
                <div className="container-input">
                    <input type='password' autocomplete='off' className="input-class white login text-center" onChange={e => setPassword(e.target.value)} name="password" required placeholder="Contraseña" />
                    <div className="icon-password"><img src="./img/icons/icon_password.svg" className="img-responsive" alt="Cart"/></div>
                </div>
                <div className="forgot-container"><a href="https://contitools.com/forgot-password" className="button-forgot">¿Olvidaste tu constraseña?</a></div>
            </div>
            <div className="container-form">
                <div className="container-input">
                    <button type="submit" className="btn-action login button-init">
                        <span>Ingresar</span>
                    </button>
                </div>
                <div className="container-input">
                    <button onClick={() => window.open('https://contitools.com/create')} className="btn-action login button-init" id="button-register">
                        <span>Registrarme</span>
                    </button>
                </div>
            </div>
            </form>
        </div>
  </div>
  <div className="tire-home"><img src="./img/backgroundtire.jpg"  alt=""/></div>
</div>
  );
}
export {Login};