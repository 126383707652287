import React, {useEffect, useState} from "react";
import {
  useNavigate,
  useLocation
} from "react-router-dom";
import {getProducts} from "../Product/apiProducts";
const ProductFactBook = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [products, setProducts] = useState([]);
  let query = useLocation();
  query = query.pathname.split('/');
  const id  = query[2];
  const fetchProducts = async () => {
    const fetchedProducts = await getProducts({id, includeAll: false});
    setProducts(fetchedProducts)
  }
  let product = {};
  if(products.length) {
    product = products[0];
  }
  useEffect(() => {
    fetchProducts();
  }, []);
  return(
    <div className="container-page">
        <div className="header-form">
            <div className="title-icon">
                <img src="/img/new/icon_factbook.svg" className="img-responsive" alt="Cart"/>
                <span>ProductFact</span>
            </div>
            <div className="header-callaction">
                <button onClick={() => handleClick('/product_factbooks')} className="btn-action gray-darker button-init btn-header active" id="butonFilter">
                    Volver
                </button>
                <button onClick={() => handleClick()} className="close-header">
                    <img src="/img/icons/icon_close.svg" alt="close" />
                </button>
            </div>
        </div>
        <div className="container-fluid container-information scroll-object">
            <div className="row no-gutters">
                <div className="col-12">
                <iframe src={product.factbook} width='100%' height='600px' frameborder='0'>Esto es un documento de <a target='_blank' href='https://office.com'>Microsoft Office</a> incrustado con tecnología de <a target='_blank' href='https://office.com/webapps'>Office Online</a>.</iframe>
                </div>
            </div>
        </div>
    </div>
  )
}

export {ProductFactBook};