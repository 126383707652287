import React from "react";

const WidthSelector =  ({widths, onChange}) => {
 return (<>
    <select onChange={onChange} className="input-class text-center">
        <option value={null}>Ancho*</option>
        { widths.map((width, k) => {
          return(<option key={k} value={width}>{width}</option>)
        })}
    </select>
    <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
</>)
}
export {WidthSelector};