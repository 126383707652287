import React, {useEffect, useState} from "react";
import {getProducts} from "../Product/apiProducts";
const SearchProducts =  ({filterProducts}) => {
  const [products, setProducts] = useState([]);
  const fetchProducts = async () => {
    let fetchedProducts = await getProducts();
    fetchedProducts = fetchedProducts.map((product) => {
      const brand = product.brand_id === 1 ? 'continental' : 'general tires';
      product.brand = brand;
      return product;
    })
    setProducts(fetchedProducts)
  }
  useEffect(() => {
    fetchProducts();
  }, []);
  const findProducts = (searchValue) => {
    const productsFiltered = products.filter((product) => {
      return product.description.toUpperCase().includes(searchValue.toUpperCase()) || product.brand.toUpperCase().includes(searchValue.toUpperCase())
    });

    filterProducts(productsFiltered);
  }
 return (<input placeholder="Buscar" onChange={(event) => findProducts(event.target.value)} className="input-class"/>)
}
export {SearchProducts};