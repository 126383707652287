import React, {} from "react";
import {Link} from "react-router-dom";
import {baseUrlImages} from '../config';
import { setCart, existsInCart, getCart } from "../Quoter/quoterProvider";

const ProductContainer =  ({product, databooks, refreshCart, factbook}) => {
  const link = factbook ? '/product_factbook/'+product.id : '/product/'+product.id;
  let color = 'yellow';
  if(product?.brand_id === 2) {
    color = 'red';
  }
  if(product?.brand_id === 3) {
    color = 'blue';
  }
  if(product?.brand_id === 4) {
    color = 'green';
  }
 return (<div key={product.id} className="container-tire container-tire-one col-12 col-sm-6 col-md-3 col-lg-2">
    <Link to={link}>
    <div className="img-tire">
        <img src={`${baseUrlImages}/${product.picture}`} alt="" className="img-responsive" />
    </div>
   </Link>
   <div className="content-information-2">
       <div className={`title-information ${!databooks ? 'border-complete' : ''}`}>
           <div className={`circle-color ${color}`}>&nbsp;</div>
           <span>{product.description}</span>
      </div>
           {databooks?.map((databook, k) => {
             databook.plt_databook.productPicture = product.picture;
             databook.plt_databook.productDescription = product.description;
             databook.plt_databook.brand_id = product.brand_id;
             databook.plt_databook.price = databook.price;
      return(
        <div key={k} className={`cart-information ${k+1 === databooks.length ? 'border-complete' : ''}`}>
         <span>{databook.plt_databook.ssr ? '*' : ''}{databook.plt_databook.mesure}-{databook.plt_databook.ic_iv} <br/>{databook.plt_databook.price ? `$${databook.plt_databook.price}` : ''}</span>
         {!existsInCart(databook.plt_databook.id) && <a className="icon_cart" onClick={() => {setCart(databook.plt_databook); if(refreshCart) {refreshCart(getCart())}}} href="#"><img src="img/icons/icon_cart_plus.svg" className="img-responsive" alt="Cart"/></a>}
        </div>
      )
    })}
   </div>
</div>)
}
export {ProductContainer};