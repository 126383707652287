import React, { useState } from "react";
import {authContext} from './authContext';
import { setLocalStorage, getLocalStorage } from "../LocalStorage/localStorageProvider";
import model from '../core/model';
const useProvideAuth = () => {
  const [user, setUser] = useState(getLocalStorage('user'));

  const signin = async (credentials) => {
    let user = await model.post('/auth/login', credentials);
    user = user.data;
    model.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
    const userData = await model.get('/user');
    user.data = userData.data;
    setUser(user)
    setLocalStorage('user', user);
  };
  const signout = async () => {
    await model.get('/auth/logout')
    setUser(null)
    setLocalStorage('user', null);
    model.defaults.headers.common['Authorization'] = null;
  };

  return {
    user,
    signin,
    signout
  };
};
const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}
export {ProvideAuth, useProvideAuth}