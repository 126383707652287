import React, {useEffect, useState} from "react";
import {useAuth} from '../Auth/authContext';
import {
  Link, useLocation
} from "react-router-dom";
import { getCart } from "../Quoter/quoterProvider";

const Header = () => {
  const {pathname} = useLocation();
  const auth = useAuth();
  const [cart, setCart] = useState(getCart());
  const [menuStatus, setMenuStatus] = useState(false);
  const activeInactiveMenu = () => {
    setMenuStatus(!menuStatus);
  }
  useEffect(() => {
      setTimeout(() => {
        const newCart = getCart();
        setCart(newCart);
    }, 1000)
  }, [cart, auth.user]);

  return (
    <header>
      <Link to="/home" className="iconhome"><img src="/img/ico_contigo.png" className="img-responsive" alt="Continental"/></Link>
      {auth.user && <img src={`/img/${auth.user?.data?.country_id}_flag.png`} className="flag" alt="flag" /> }
      {auth.user && (<div className="content-buttons">
        {pathname !== '/quoter' &&
      (<Link to="/quoter" className="iconcart">
          <img src="/img/icons/icon_cart.svg" className="img-responsive" alt="Cart"/>
          {cart.length > 0 && 
            <span className={`numbercart`}>{cart.length}</span>
          }
        </Link>)}
        {pathname === '/quoter' &&
      (<Link to="/home" className="btn-close-cotizador">
          <img src="/img/new/close_menu.svg" className="img-responsive" alt="Cart"/>
        </Link>)}
          <div onClick={() => activeInactiveMenu()} className={` ${menuStatus ? 'active': ''}`} id="menu-page"><span>&nbsp;</span></div>
          <button onClick={() => auth.signout() } className="btn-action border-100 button-init button-close-session">
              <span>Cerrar sesi&oacute;n</span>
              <img src="/img/icons/icon_sesion.svg" className="img-responsive" alt="Cart"/>
          </button>
          <div className={`modal-background container-menu ${menuStatus ? 'active': ''}`}>
            <div className={`menu-continental ${menuStatus ? 'active': ''}`}>
              <div className="just-backgradient black">
              <Link to="/tireselector" className="button-menu">
                <img src="/img/new/icon_tireselector.svg" className="img-responsive"  alt=""/>
                <span>TireSelector</span>
              </Link>
              <Link to="/products" className="button-menu">
                <img src="/img/new/icon_fichatecnica.svg" className="img-responsive"  alt=""/>
                <span>Ficha T&eacute;cnica</span>
              </Link>
              <Link to="/product_guides" className="button-menu">
                <img src="/img/new/icon_guias.svg" className="img-responsive"  alt=""/>
                <span>Guías de productos</span>
              </Link>
              <Link to="/toolbox" className="button-menu">
                <img src="/img/new/icon_toolbox.svg" className="img-responsive"  alt=""/>
                <span>ToolBox</span>
              </Link>
              </div>
              <div className="just-backgradient yellow">
                <Link to="/quoter" className="button-menu">
                  <img src="/img/new/icon_cotizador.svg" className="img-responsive"  alt=""/>
                  <span>Cotizador</span>
                </Link>
                <Link to="/videos" className="button-menu">
                  <img src="/img/new/icon_videos.svg" className="img-responsive"  alt=""/>
                  <span>Videos</span>
                </Link>
                <Link to="/product_factbooks" className="button-menu">
                  <img src="/img/new/icon_factbook.svg" className="img-responsive"  alt=""/>
                  <span>Product FactBook</span>
                </Link>
                <Link to="/news" className="button-menu">
                  <img src="/img/new/icon_continews.svg" className="img-responsive"  alt=""/>
                  <span>ContiNews</span>
                </Link>
            </div>
            </div>
        </div>
        </div> 
        )}
    </header>
  );
}
export {Header};