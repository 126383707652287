import React, {useEffect} from "react";
import lottie from 'lottie-web';
import {
  Link
} from "react-router-dom";

const Home = () => {
  useEffect(() => {
    var anim_tireselector;
    var anim_ficha;
    var anim_guiaproducts;
    var anim_toolbox;
    var anim_cotizador;
    var anim_videos;
    var anim_productfacts;
    var anim_continews;

    var params_tireselector = {
        container: document.getElementById('json_tireselector'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/tireselector.json'
    };
    var params_ficha = {
        container: document.getElementById('json_ficha'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/fichatecnica.json'
    };
    var params_guiaproducts = {
        container: document.getElementById('json_guiaproducto'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/guiaproducto.json'
    };
    var params_toolbox = {
        container: document.getElementById('json_toolbox'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/toolbox.json'
    };
    var params_cotizador = {
        container: document.getElementById('json_cotizador'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/cotizador.json'
    };
    var params_videos = {
        container: document.getElementById('json_videos'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/videos.json'
    };
    var params_productfact = {
        container: document.getElementById('json_productfact'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/productfact.json'
    };
    var params_continews = {
        container: document.getElementById('json_continews'),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '/img/json/continews.json'
    };
    
    anim_tireselector = lottie.loadAnimation(params_tireselector);
    anim_ficha = lottie.loadAnimation(params_ficha);
    anim_guiaproducts = lottie.loadAnimation(params_guiaproducts);
    anim_toolbox = lottie.loadAnimation(params_toolbox);
    anim_cotizador = lottie.loadAnimation(params_cotizador);
    anim_videos = lottie.loadAnimation(params_videos);
    anim_productfacts = lottie.loadAnimation(params_productfact);
    anim_continews = lottie.loadAnimation(params_continews);

    const btn_tireselector =  document.querySelector(".tireselector");
    const btn_ficha =  document.querySelector(".fichatecnica");
    const btn_guiaproducts =  document.querySelector(".guiaproducts");
    const btn_toolbox =  document.querySelector(".toolbox");
    const btn_cotizador =  document.querySelector(".cotizador");
    const btn_productfact =  document.querySelector(".productfact");
    const btn_videos =  document.querySelector(".videosjson");
    const btn_continews =  document.querySelector(".continews");

    btn_tireselector.addEventListener('mouseenter',()=>{
      anim_tireselector.play();
    });
    btn_tireselector.addEventListener('mouseleave',()=>{
      anim_tireselector.stop();
    });

    btn_ficha.addEventListener('mouseenter',()=>{
      anim_ficha.play();
    });
    btn_ficha.addEventListener('mouseleave',()=>{
      anim_ficha.stop();
    });

    btn_guiaproducts.addEventListener('mouseenter',()=>{
      anim_guiaproducts.play();
    });
    btn_guiaproducts.addEventListener('mouseleave',()=>{
      anim_guiaproducts.stop();
    });

    btn_toolbox.addEventListener('mouseenter',()=>{
      anim_toolbox.play();
    });
    btn_toolbox.addEventListener('mouseleave',()=>{
      anim_toolbox.stop();
    });

    btn_cotizador.addEventListener('mouseenter',()=>{
      anim_cotizador.play();
    });

    btn_cotizador.addEventListener('mouseleave',()=>{
      anim_cotizador.stop();
    });

    btn_videos.addEventListener('mouseenter',()=>{
      anim_videos.play();
    });

    btn_videos.addEventListener('mouseleave',()=>{
      anim_videos.stop();
    });

    btn_productfact.addEventListener('mouseenter',()=>{
      anim_productfacts.play();
    });
    btn_productfact.addEventListener('mouseleave',()=>{
      anim_productfacts.stop();
    });

    btn_continews.addEventListener('mouseenter',()=>{
      anim_continews.play();
    });
    btn_continews.addEventListener('mouseleave',()=>{
      anim_continews.stop();
    });
    
  });
  return (
<section className="container-home">
    <div className="section-black">
      <div className="tire-home up">
        <img src="/img/new/tire_up.png" className="img-responsive" alt="" />
      </div>
      <div className="backgradient black">&nbsp;</div>
      <div className="container-options">
      <Link to="/quoter" className="icon-option black cotizador">
          <div id='json_cotizador' className="lottie-icon"></div>
        </Link>
        <Link to="/products" className="icon-option fichatecnica">
          <div id='json_ficha' className="lottie-icon"></div>
        </Link>
        <Link to="/product_guides" className="icon-option guiaproducts">
          <div id='json_guiaproducto' className="lottie-icon"></div>
        </Link>
        <Link to="/news" className="icon-option black continews">
          <div id="json_continews" className="lottie-icon"></div>
        </Link>
      </div>
    </div>
    <div className="section-yellow">
      <div className="tire-home down">
        <img src="/img/new/tire_down.png" className="img-responsive" alt="" />
      </div>
      <div className="backgradient yellow">&nbsp;</div>
      <div className="container-options">
      <Link to="/tireselector" className="icon-option tireselector">
          <div id='json_tireselector' className="lottie-icon"></div>
        </Link>
        <Link to="/videos" className="icon-option black videosjson">
          <div id='json_videos' className="lottie-icon"></div>
        </Link>
        <Link to="/product_factbooks" className="icon-option black productfact">
          <div id="json_productfact" className="lottie-icon"></div>
        </Link>
        <Link to="/toolbox" className="icon-option toolbox">
          <div id='json_toolbox' className="lottie-icon"></div>
        </Link>
      </div>
    </div>
  </section>
  );
}
export {Home};