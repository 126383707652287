import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
const HomeContainer = (props) => {
  useEffect(() => {
    
  });
  return (
<section className="container-home">
<Helmet>
            <title> { `Contigo - ${ props.title }` }</title>
            <meta name="description" content="Nested component" />
  </Helmet>
 
    <div className="section-black">
      <div className="tire-home up">
        <img src="/img/new/tire_up.png" className="img-responsive" alt="" />
      </div>
      <div className="backgradient black">&nbsp;</div>
    </div>
    <div className="section-yellow">
      <div className="tire-home down">
        <img src="/img/new/tire_down.png" className="img-responsive" alt="" />
      </div>
      <div className="backgradient yellow">&nbsp;</div>
    </div>
    {props.children}
  </section>
  );
}
export {HomeContainer};