import React, {useEffect, useState} from "react";
import {
  useNavigate,
} from "react-router-dom";
import {getDatabooks} from "../Databook/apiDatabooks";
import {getVehicles} from "../Vehicles/apiVehicles";
import {getParams} from "../Params/apiParams";
import {pluck, groupBy, onlyUnique} from '../helpers/helpers';
import { ProductContainer } from "../Product/ProductContainer";
import { SegmentSelector } from "./SegmentSelector";
import { WidthSelector } from "./WidthSelector";
import { SerieSelector } from "./SerieSelector";
import { RingSelector } from "./RingSelector";
const TireSelector = () => {
  const history = useNavigate();
  const handleClick = (route = '/home') => {
    history(route);
  }
  const [databooks, setDatabooks] = useState([]);
  const [params, setParams] = useState([]);
  const [products, setProducts] = useState({});
  const [vehicles, setVehicles] = useState([]);
  const [brandId, setBrandId] = useState(null);
  const [modelId, setModelId] = useState(null);
  const [year, setYear] = useState(null);
  const [mesure, setMesure] = useState(null);
  const [segment, setSegment] = useState(null);
  const [filterType, setFilterType] = useState('mesure');
  const [topFilter, setTopFilter] = useState('original');
  const [priceDatabooks, setPriceDatabooks] = useState([]);
  const [widths, setWiths] = useState([]);
  const [series, setSeries] = useState([]);
  const [rings, setRings] = useState([]);
  const [width, setWith] = useState(null);
  const [serie, setSerie] = useState(null);
  const [ring, setRing] = useState(null);
  const [ssr, setSsr] = useState(0);
  const fetchDatabooks = async () => {
    const fetchedDatabooks = await getDatabooks({type: 'PLT', includeAll: true});
    setPriceDatabooks([...fetchedDatabooks]);
    const fetchedDatabooksPluck = pluck(fetchedDatabooks, 'plt_databook');
    setDatabooks(fetchedDatabooksPluck);
    setWiths([...new Set(fetchedDatabooksPluck?.map((databook) =>databook.width))].sort());
  }
  const fetchParams = async () => {
    const fetchedParams = await getParams({type: 'TECHNICAL'});
    setParams([...fetchedParams]);
  }
  const fetchVehicles = async () => {
    const fetchedVehicles = await getVehicles();
    setVehicles([...fetchedVehicles]);
  }
  const selectWith = (value) => {
    setWith(value);
    const series = [...new Set(databooks?.filter((databook) => databook.width.toString() === value.toString()).map((databook) =>databook.series))].sort();
    setSerie(null);
    setRing(null);
    setSeries(series);
  }
  const selectSerie = (value) => {
    setSerie(value);
    const rings = [...new Set(databooks
      .filter((databook) => databook.width.toString() === width.toString() && databook.series.toString() === value.toString())
      .map((databook) =>databook.ring))]
      .sort()
    setRing(null);
    setRings(rings);
  }
  const selectRing = (value) => {
    setRing(value);
  }
  const selectYear = (value) => {
    setYear(value);
    const rings = vehicles
      .find((brand) => brand.id.toString() === brandId)
      ?.vehicle_models
      ?.find((model) => model.id.toString() === modelId)
      ?.vehicle_versions
      ?.filter((version) => version.year.toString() === value)
      .map((version) => version.ring)
      .filter(onlyUnique)
      .sort()
    setRings(rings)
  }
  const selectMesure = (value) => {
    setMesure(value);
    const version = vehicles
    .find((brand) => brand.id.toString() === brandId)
    ?.vehicle_models
    ?.find((model) => model.id.toString() === modelId)
    ?.vehicle_versions
    ?.find((version) => version.mesure.toString() === value);
    if(version) {
      // const ssr = version.ssr;
      setWith(version.width.toString());
      setSerie(version.series.toString());
      setRing(version.ring.toString());
      setSegment(version.vehicle_type);
    }
  }
  const filterProducts = (_topFilter) => {
    let databooksFiltered = priceDatabooks.filter((databook) => {
      let conditional;
      if(_topFilter === 'original') {
        conditional = databook.plt_databook.width.toString() === width && databook.plt_databook.series.toString() === serie && databook.plt_databook.ring.toString() === ring;
        if(ssr !== -1) {
         conditional = conditional && databook.plt_databook.ssr.toString() === ssr.toString();
        }
      }
      else{
        if(ssr !== -1) {
          conditional = databook.plt_databook.ssr === ssr;
        }else{
          conditional = true;
        }
      }

      if(segment) {
        conditional = conditional && databook.plt_databook[segment] === 1;
      }

      return conditional;
    });

    if(_topFilter && _topFilter !== 'original') {
      const newSegment = segment || 'automovil';
      const originalDiameter = (width * (serie/100) * 2) + ring * 25.4;
      const positiveDiameter = originalDiameter + 
        ((Number(params.find((param) => param.description === `${newSegment}-POSITIVE-D`).value) / 100) * originalDiameter);
      const negativeDiameter = originalDiameter - 
        ((Number(params.find((param) => param.description === `${newSegment}-NEGATIVE-D`).value) / 100) * originalDiameter);
      const positiveWidth = Number(width) + 
      Number(params.find((param) => param.description === `${newSegment}-POSITIVE-W`).value);
      const negativeWidth = Number(width) - 
      Number(params.find((param) => param.description === `${newSegment}-NEGATIVE-W`).value);
      let newRing = Number(ring);
      if(_topFilter === 'plus1') {
        newRing = newRing + 1;
      }
      if(_topFilter === 'plus2') {
        newRing = newRing + 2;
      }
      databooksFiltered = databooksFiltered.filter((databook) => {
        return databook.plt_databook.width >= negativeWidth 
        && databook.plt_databook.width <= positiveWidth 
        && databook.plt_databook.diameter_mm >= negativeDiameter 
        && databook.plt_databook.diameter_mm <= positiveDiameter
        && databook.plt_databook.ring === newRing ;
        
      });
    }
    console.log(databooksFiltered);
    const byProducts = groupBy(databooksFiltered, 'product_id');
    setProducts(byProducts);
  }

  useEffect(() => {
    fetchDatabooks();
    fetchParams();
    fetchVehicles();
  }, []);
  return(
    <div className="container-page options">
    <div className="header-form options">
        <div className="title-icon">
            <img src="/img/new/icon_tireselector_b.svg" className="img-responsive" alt="Cart"/>
            <span>Tire Selector</span>
        </div>
        <div className="header-callaction options">
            <button onClick={() => {setTopFilter('original'); filterProducts('original');}} className={`btn-action gray-darker btn-header ${topFilter === 'original' ? 'active' : ''}`}>
                Medida original
            </button>
            <button onClick={() => {setTopFilter('compatible'); filterProducts('compatible');}} className={`btn-action gray-darker btn-header ${topFilter === 'compatible' ? 'active' : ''}`}>
                Medida compatible
            </button>
            <button onClick={() => {setTopFilter('plus1'); filterProducts('plus1');}} className={`btn-action gray-darker btn-header ${topFilter === 'plus1' ? 'active' : ''}`}>
                Plus 1 (+1 Rin)
            </button>
            <button onClick={() => {setTopFilter('plus2'); filterProducts('plus2');}} className={`btn-action gray-darker btn-header ${topFilter === 'plus2' ? 'active' : ''}`}>
                Plus 2 (+2 Rin)
            </button>
            <button onClick={() => handleClick()}  className="close-header options" id="button-close">
                <img src="/img/icons/icon_close.svg" alt="close" className="img-responsive"/>
            </button>
        </div>
    </div>
    <div className="container-fluid container-information scroll-object">
        <div className="row no-gutters d-flex">
            <div className="container-filter container-left-filters">
                <div className="filters">
                    <div className="information-filters">
                        <p>Define los parámetros de búsqueda para la llanta que se adapte mejor a tu vehículo y tus exigencias</p>
                        <div className="button-filter">
                            <button onClick={() => {setFilterType('mesure'); setWith(null); setSerie(null); setRing(null); setSsr(0);}} className={`btn-optionfilter ${filterType === 'mesure' ? 'active' : ''}`} data-type="measure">
                                Medida
                            </button>
                            <button onClick={() => {setFilterType('vehicle'); setWith(null); setSerie(null); setRing(null);setSsr(-1);}} className={`btn-optionfilter ${filterType === 'vehicle' ? 'active' : ''}`} data-type="vehicle">
                                Vehículo
                            </button>
                        </div>  
                        {filterType === 'mesure' && <div className="field-modal">
                            <p>Parametros de búsqueda:</p>
                            <div className="container-input">
                                <SegmentSelector onChange={(event) => setSegment(event.target.value)} />
                            </div>
                            <div className="container-input">
                                <WidthSelector widths={widths} onChange={(event) => selectWith(event.target.value)} />
                            </div>
                            <div className="container-input">
                              <SerieSelector series={series} serie={serie} onChange={(event) => selectSerie(event.target.value)} />
                            </div>
                            <div className="container-input">
                              <RingSelector ring={ring} onChange={(event) => selectRing(event.target.value)} rings={rings} />
                            </div>
                            <div className="container-input">
                            <input type="checkbox" onClick={(event) => {const value = event.target.checked ? 1 : 0; setSsr(value)}}  value={1} /> <label htmlFor="custom7">SSR</label>
                            <label> * Self Supporting RunFlat - SSR </label>
                            <label> Las Medidas Compatibles, Plus 1 y Plus 2, son recomendaciones técnicas, sujetas a verificaciones físicas en el vehículo.</label>
                            </div>
                        </div>}
                        {filterType === 'vehicle' && <div className="field-modal">
                            <p>Parametros de búsqueda:</p>
                            <div className="container-input">
                                <select onChange={(event) => {setBrandId(event.target.value); setModelId(null); setYear(null); setRing(null); setMesure(null);}} className="input-class text-center">
                                    <option>Marca*</option>
                                    { vehicles.map((brand, k) => {
                                      return(<option key={k} value={brand.id}>{brand.description}</option>)
                                    })}
                                </select>
                                <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                            </div>
                            <div className="container-input">
                                <select onChange={(event) => {setModelId(event.target.value); setYear(null); setRing(null); setMesure(null); }} className="input-class text-center">
                                    <option defaultValue={!modelId}>Modelo*</option>
                                    { vehicles.find((brand) => brand.id.toString() === brandId)?.vehicle_models.map((model, k) => {
                                      return(<option key={k} value={model.id}>{model.description}</option>)
                                    })}
                                </select>
                                <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                            </div>
                            <div className="container-input">
                                <select onChange={(event) => {selectYear(event.target.value); setRing(null); setMesure(null); }} className="input-class text-center">
                                    <option defaultValue={!year}>Año*</option>
                                    { [...new Set(vehicles
                                      .find((brand) => brand.id.toString() === brandId)
                                      ?.vehicle_models
                                      ?.find((model) => model.id.toString() === modelId)
                                      ?.vehicle_versions
                                      .map((version, k) => {
                                        return version.year
                                      }))].sort().map((year, k) => {
                                        return(<option key={k} value={year}>{year}</option>)
                                      })}
                                </select>
                                <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                            </div>
                            <div className="container-input">
                                <select onChange={(event) => {selectRing(event.target.value); setMesure(null); }} className="input-class text-center">
                                    <option defaultValue={!ring}>Rin*</option>
                                    { rings.map((ring, k) => {
                                      return(<option key={k} value={ring}>{ring}</option>)
                                    })}
                                </select>
                                <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                            </div>
                            <div className="container-input">
                                <select onChange={(event) => {selectMesure(event.target.value);}} className="input-class text-center">
                                    <option defaultValue={!mesure}>Medida*</option>
                                    { [...new Set(vehicles
                                      .find((brand) => brand.id.toString() === brandId)
                                      ?.vehicle_models
                                      .find((model) => model.id.toString() === modelId)
                                      ?.vehicle_versions
                                      .filter((version) => version.year.toString() === year && version.ring.toString() === ring)
                                      ?.map((version, k) => {
                                        return version.mesure;
                                      }))].sort().map((mesure, k) => {
                                        return(<option key={k} value={mesure}>{mesure}</option>)
                                      })}
                                </select>
                                <img src="/img/icons/icon_arrow.svg" alt="" className="icon-select" />
                            </div>
                            <div className="container-input">
                              <label> * Self Supporting RunFlat - SSR </label>
                              <label> Las Medidas Compatibles, Plus 1 y Plus 2, son recomendaciones técnicas, sujetas a verificaciones físicas en el vehículo.</label>
                            </div>
                        </div>}
                    </div>
                    <div className="footer-filters">
                        <button onClick={() => filterProducts(topFilter)} className="btn-action button-init w-100">Buscar</button>
                    </div>
                </div>
            </div>
            <div className="container-right-filters">
                <div className="container-tires row no-gutters">
                  {!Object.keys(products).length && <div>No existen productos</div>}
                    {Object.keys(products).map((productId) => {
                      const databookByProduct = products[productId];
                      const product = databookByProduct[0]?.product;
                      if(product?.brand_id === 1){
                    return(<ProductContainer key={product.id} product={product} databooks={databookByProduct}/>)
                      }
                    })}
                    {Object.keys(products).map((productId) => {
                      const databookByProduct = products[productId];
                      const product = databookByProduct[0]?.product;
                      if(product?.brand_id === 2) {
                        return(<ProductContainer key={product.id} product={product} databooks={databookByProduct}/>)
                          }
                    })}
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export {TireSelector};